<template>
  <div class="table-wrap">
    <table
      class="table"
      align="center"
      cellspacing="0"
      cellpadding="15"
    >
      <thead>
        <tr>
          <th
            v-for="value in fields"
            :key="value"
          >
            {{ value }}
          </th>
          <th class="table-actions">{{ editable ? 'Действия' : 'Открыть' }}</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, idx) in data"
          :key="idx"
          align="center"
          valign="center"
        >
          <td
            v-for="(_, prop) in fields"
            :key="prop"
          >
            <PointsCell
              v-if="fields[prop] === 'Оценка'"
              :value="item[prop]"
            />
            <ScaleCell
              v-else-if="fields[prop] === 'Шкала об-cти'"
              :points="item[prop]"
            />
            <template v-else>
              {{ item[prop] }}
            </template>
          </td>
          <td>
            <div class="table--btn-group">
              <app-button
                type="square"
                @click="$emit('selected', item)"
                :style="{ marginRight: editable ? '10px' : '' }"
              >
                <DescriptionIcon />
              </app-button>

              <app-button
                v-if="editable"
                type="square"
                @click="$emit('edit', item)"
                :style="{ marginRight: editable ? '10px' : '' }"
              >
                <EditIcon />
              </app-button>

              <app-button
                v-if="editable"
                type="square"
                @click="$emit('remove', item)"
              >
                <RemoveIcon />
              </app-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import PointsCell from "../PointsCell";
import ScaleCell from "../ScaleCell";
import AppButton from '@/components/ui/AppButton'
import DescriptionIcon from '@/components/icons/DescriptionIcon'
import EditIcon from '@/components/icons/EditIcon'
import AddIcon from '@/components/icons/AddIcon'
import RemoveIcon from '../icons/RemoveIcon'

export default {
  name: 'AppTable',
  components: {
    PointsCell,
    ScaleCell,
    AppButton,
    DescriptionIcon,
    EditIcon,
    AddIcon,
    RemoveIcon
  },
  emits: ['selected', 'edit', 'edit-title', 'add', 'remove'],
  props: {
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>
.table--btn-group {
  display: flex;
  justify-content: center;
}
</style>
