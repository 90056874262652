<template>
  <div class="scale">
    <div
      v-for="title in scaleTitles"
      class="scale__cell"
    >
      <span style="margin-bottom: 5px; font-size: 0.85rem;">{{ title }}</span>
      <div class="scale__indicator" :class="{ active: title === points }">
        <ScaleIcon v-if="title === points" />
      </div>
    </div>
  </div>
</template>

<script>
import ScaleIcon from "./icons/ScaleIcon";
export default {
  name: 'ScaleCell',
  components: { ScaleIcon },
  props: {
    points: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      scaleTitles: ['-3', '-2', '-1', '0', '+1', '+2', '+3']
    }
  }
}
</script>
