<template>
  <span class="table__points">{{ value }}</span>
</template>

<script>
export default {
  name: 'PointsCell',
  props: {
    value: {
      required: true
    }
  }
}
</script>
