import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import chunk from 'lodash.chunk'

export function useFilterAndPagination(categoryName, isAdmin = false) {
  const store = useStore()
  const route = useRoute()
  const router = useRouter()

  const page = ref(1)
  const PAGE_ITEMS_COUNT = 10

  const filter = ref({})

  watch(filter, () => page.value = 1)

  const items = computed(() => store.getters[`data/${categoryName}`]
    .filter(item => {
      if (filter.value.name) {
        return item.name.toLowerCase().includes(filter.value.name.toLowerCase())
      }
      return item
    })
    .filter(item => {
      if (filter.value.genre) {
        return item.genre.toLowerCase().includes(filter.value.genre.toLowerCase())
      }
      return item
    })
    .filter(item => {
      if (filter.value.release) {
        if (typeof item.release === 'number') {
          return item.release.toString().toLowerCase().includes(filter.value.release.toLowerCase())
        }
        return item.release.toLowerCase().includes(filter.value.release.toLowerCase())
      }
      return item
    })
    .filter(item => {
      if (filter.value.points) {
        if (typeof item.points === 'number') {
          return item.points.toString().toLowerCase().includes(filter.value.points.toLowerCase())
        }
        return item.points.toLowerCase().includes(filter.value.points.toLowerCase())
      }
      return item
    })
    .filter(item => {
      if (filter.value.scale && filter.value.scale !== 'all') {
        if (item.scale === null) {
          return true;
        }

        return item.scale.includes(filter.value.scale)
      }
      return item
    })
    .filter(item => {
      if (filter.value.country) {
        return item.country.toLowerCase().includes(filter.value.country.toLowerCase())
      }
      return item
    })
  )

  const chunkItems = computed(() => chunk(items.value, PAGE_ITEMS_COUNT))
  const currentPageItems = computed(() => chunkItems.value[page.value - 1])

  watch(page, value => {
    const query = { page: value }
    if (value === 1) delete query.page
    router.replace({ path: `${isAdmin ? '/admin' : ''}/${categoryName}`, query })
  })
  onMounted(() => {
    if (route.query.page) {
      page.value = +route.query.page
    }
  })

  return {
    currentPageItems,
    PAGE_ITEMS_COUNT,
    page,
    filter,
    items
  }
}
