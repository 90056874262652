<template>
  <div class="scale-checkbox">
    <label class="scale-label">
      <span class="scale-text">-3</span>
      <input class="scale-radio" type="radio" v-model="checkedScale" value="-3">
      <span class="scale-fake"></span>
    </label>

    <label class="scale-label">
      <span class="scale-text">-2</span>
      <input class="scale-radio" type="radio" v-model="checkedScale" value="-2">
      <span class="scale-fake"></span>
    </label>

    <label class="scale-label">
      <span class="scale-text">-1</span>
      <input class="scale-radio" type="radio" v-model="checkedScale" value="-1">
      <span class="scale-fake"></span>
    </label>

    <label class="scale-label">
      <span class="scale-text">0</span>
      <input class="scale-radio" type="radio" v-model="checkedScale" value="0">
      <span class="scale-fake"></span>
    </label>

    <label class="scale-label">
      <span class="scale-text">+1</span>
      <input class="scale-radio" type="radio" v-model="checkedScale" value="+1">
      <span class="scale-fake"></span>
    </label>

    <label class="scale-label">
      <span class="scale-text">+2</span>
      <input class="scale-radio" type="radio" v-model="checkedScale" value="+2">
      <span class="scale-fake"></span>
    </label>

    <label class="scale-label">
      <span class="scale-text">+3</span>
      <input class="scale-radio" type="radio" v-model="checkedScale" value="+3">
      <span class="scale-fake"></span>
    </label>

    <label v-if="!modal" class="scale-label">
      <span class="scale-text">Все</span>
      <input class="scale-radio" type="radio" v-model="checkedScale" value="all">
      <span class="scale-fake"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ScaleCheckbox',
  emits: ['update'],
  props: {
    modelValue: String,
    modal: Boolean,
    default: {
      type: String,
      default: 'all',
      required: false
    }
  },
  data() {
    return {
      checkedScale: this.default
    }
  },
  watch: {
    checkedScale(value) {
      // this.$emit('update:modelValue', value)
      this.$emit('update', value)
    }
  }
}
</script>
