<template>
  <div class="filter-btn">
    <app-button
      v-if="isAdaptive"
      @click="visible = !visible"
    >
      {{ visible ? 'Скрыть' : 'Показать' }} фильтр
    </app-button>
  </div>

  <div class="filter" v-if="visible">
    <div class="filter-item">
      <input type="text" placeholder="Название" v-model="name">
    </div>

    <div class="filter-item">
      <input type="text" placeholder="Жанр" v-model="genre">
    </div>

    <div class="filter-item" v-if="type !== 'book'">
      <input type="text" placeholder="Дата выхода" v-model="release">
    </div>

    <div class="filter-item" v-if="type === 'film'">
      <input type="text" placeholder="Страна" v-model="country">
    </div>

    <div class="filter-item" v-if="type !== 'film'">
      <input type="text" placeholder="Оценка" v-model="points">
    </div>

    <div class="filter-item">
      <ScaleCheckbox @update="changeScale" />
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import ScaleCheckbox from './ScaleCheckbox'
import AppButton from '@/components/ui/AppButton'

export default {
  name: 'AppFilter',
  components: {
    ScaleCheckbox,
    AppButton
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: Object,
    type: {
      type: String,
      required: true
    }
  },
  setup(_, { emit }) {
    const name = ref()
    const genre = ref()
    const release = ref()
    const points = ref()
    const scale = ref('all')
    const country = ref()

    const isAdaptive = computed(() => window.innerWidth <= 1240)
    const visible = ref(!isAdaptive.value)

    const changeScale = value => {
      scale.value = value
    }

    watch([name, genre, release, points, scale, country], values => {
      emit('update:modelValue', {
        name: values[0],
        genre: values[1],
        release: values[2],
        points: values[3],
        scale: values[4],
        country: values[5]
      })
    })

    return {
      name,
      genre,
      release,
      points,
      scale,
      country,
      changeScale,
      visible,
      isAdaptive
    }
  }
}
</script>
