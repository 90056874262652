<template>
  <div class="pagination">
    <app-button
      class="gr-btn-l"
      type="square"
      style="margin-right: 5px;"
      @click="prevPage"
      :disabled="+modelValue === 1"
    >
      <i class="el-icon-arrow-left"></i>
    </app-button>

    <ul class="pager">
      <app-button
        v-if="currentPageChunk !== 0"
        type="square"
        @click="prevChunk"
        style="margin-right: 5px;"
      >...</app-button>

      <li
        class="number"
        v-for="number in currentPages"
        :key="number"
      >
        <app-button
          type="square"
          :class="{ active: modelValue === number }"
          @click="$emit('update:modelValue', number)"
        >{{ number }}</app-button>
      </li>

      <app-button
        v-if="currentPageChunk !== chunkPages.length - 1"
        class="gr-btn-r"
        type="square"
        @click="nextChunk"
        style="margin-right: 5px;"
      >...</app-button>
    </ul>

    <app-button
      type="square"
      @click="nextPage"
      :disabled="+modelValue === pagesCount"
    >
      <i class="el-icon-arrow-right"></i>
    </app-button>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import chunk from 'lodash.chunk'
import AppButton from './AppButton'

export default {
  name: 'AppPagination',
  components: { AppButton },
  emits: ['update:modelValue'],
  props: {
    modelValue: Number,
    data: Array,
    itemsCount: Number,
    maxPages: {
      type: Number,
      required: false,
      default: 10
    }
  },
  setup(props, { emit }) {
    const route = useRoute()

    const pagesCount = computed(() => Math.ceil(props.data.length / props.itemsCount))

    const pagesArray = computed(() => {
      const result = []
      for (let i = 1; i <= pagesCount.value; i++) {
        result.push(i)
      }
      return result
    })

    const chunkPages = computed(() => chunk(pagesArray.value, props.maxPages))
    const currentPageChunk = ref(0)

    const currentPages = computed(() => chunkPages.value[currentPageChunk.value])

    const prevChunk = () => {
      if (chunkPages.value[currentPageChunk.value - 1].indexOf(+props.modelValue) === -1) {
        currentPageChunk.value -= 1
        const currentChunkPages = chunkPages.value[currentPageChunk.value]
        emit('update:modelValue', currentChunkPages[currentChunkPages.length - 1])
      } else {
        currentPageChunk.value -= 1
      }
    }
    const nextChunk = () => {
      if (chunkPages.value[currentPageChunk.value + 1].indexOf(+props.modelValue) === -1) {
        currentPageChunk.value += 1
        const currentChunkPages = chunkPages.value[currentPageChunk.value]
        emit('update:modelValue', currentChunkPages[0])
      } else {
        currentPageChunk.value += 1
      }
    }

    const nextPage = () => {
      if (chunkPages.value[currentPageChunk.value].indexOf(+props.modelValue + 1) !== -1) {
        emit('update:modelValue', +props.modelValue + 1)
      } else {
        nextChunk()
        emit('update:modelValue', +props.modelValue + 1)
      }
    }

    const prevPage = () => {
      if (chunkPages.value[currentPageChunk.value].indexOf(+props.modelValue - 1) !== -1) {
        emit('update:modelValue', +props.modelValue - 1)
      } else {
        prevChunk()
        emit('update:modelValue', +props.modelValue - 1)
      }
    }

    const correctPage = () => {
      if (
        route.query.page &&
        chunkPages.value[currentPageChunk.value].indexOf(+route.query.page) === -1
      ) {
        currentPageChunk.value += 1
        if (chunkPages.value[currentPageChunk.value].indexOf(+route.query.page) === -1) {
          correctPage()
        }
      }
    }

    onMounted(() => {
      correctPage()
    })

    const data = computed(() => props.data)
    watch(data, () => {
      currentPageChunk.value = 0
    })

    return {
      pagesCount,
      chunkPages,
      currentPageChunk,
      currentPages,
      prevChunk,
      nextChunk,
      nextPage,
      prevPage
    }
  }
}
</script>
